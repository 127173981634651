<template>
  <el-dialog
    title="变配电站"
    :visible.sync="dialogVisible"
    @close="dialogClose"
    @open="dialogOpen"
    width="1300px"
  >
    <div class="dialog-content">
      <div class="content-left">
        <div class="tree">
          <div class="tree-title">组织结构</div>
          <el-input
            placeholder="输入检索内容"
            suffix-icon="el-icon-search"
            v-model.trim="filterText"
          ></el-input>
          <!-- <el-checkbox v-model="allSelect">全选</el-checkbox> -->
          <el-tree
            ref="tree"
            :default-expand-all="false"
            :show-checkbox="false"
            :data="treeData"
            :check-on-click-node="false"
            :props="defaultProps"
            node-key="id"
            :filter-node-method="filterNode"
            @node-click="handleCheck"
          ></el-tree>
        </div>
      </div>
      <div class="content-right">
        <div class="query">
          <el-input
            v-model="requestParam.stationName"
            placeholder="请输入检索内容"
            clearable
          >
          </el-input>
          <el-button type="primary" @click="formQuery">查询</el-button>
        </div>
        <el-table
          class="table"
          :data="tableData"
          v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
        >
          <el-table-column align="center" type="index" width="100">
            <template slot-scope="scope">
              <el-radio
                @change="radioChange(scope.row)"
                v-model="radio"
                :label="scope.row.id"
              >
                {{ }}
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
                (requestParam.pageNum - 1) * requestParam.pageSize +
                scope.$index +
                1
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="stationNumber"
            label="站点编号"
            width="100"
          >
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="变配电站">
          </el-table-column>
          <el-table-column align="center" prop="companyInfoIdDesc" label="企业">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination
            :current-page="requestParam.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot"
            prev-text="上一页"
            next-text="下一页"
            background
            :total="total"
            @size-change="changeSize"
            @current-change="changeCurrent"
          >
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="dialogConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { deviceApi } from "@/api/device.js";
import { energyStationApi } from "@/api/energyStation.js";
export default {
  name: "stationDialog",
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    stationId: {
      type: Number,
      default: false,
      // validator: function (value) {
      //   // 将传入的字符串转换为数字
      //     this.stationId = Number(value);
      //   return true
      // }
    },
  },
  data() {
    return {
      dialogVisible: false,
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      filterText: "",
      loading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        stationName: "",
        companyInfoId: "",
      },
      defaultParam: {
        pageNum: 1,
        pageSize: 10,
        stationName: "",
        companyInfoId: "",
      },
      total: 0,
      inputValue: "",
      radio: "",
      stationInfo: {},
    };
  },

  mounted() {
    this.queryUnitName();
    // this.queryEnergyStation();
  },

  methods: {
    //点击关闭弹窗
    dialogClose() {
      this.stationInfo = {};
      this.radio="";
      Object.assign(this.requestParam, this.defaultParam);
      this.$emit("statusChange", this.dialogVisible);
    },
    dialogOpen() {
      this.queryUnitName();
      this.queryEnergyStation();
      if(this.stationId){
        this.radio=this.stationId;
      }
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log("点击了", "checkedNodes");
      this.requestParam.companyInfoId = checkedNodes.id;
      this.queryEnergyStation();
    },
    radioChange(item) {
      if (this.radio) {
        this.stationInfo = item;
      } else {
        this.stationName = "";
      }
    },
    dialogConfirm() {
      if (this.radio) {
        this.dialogVisible = false;
        this.$emit("selectedStation", this.stationInfo);
      } else {
        this.$message({
          message: "请选择变配电站",
          type: "error",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryEnergyStation();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryEnergyStation();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryEnergyStation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryEnergyStation();
    },
    queryUnitName() {
      deviceApi.queryUnitName().then((res) => {
        if (res.code === 200) {
          this.treeData = res.data;
          this.queryEnergyStation();
          if(this.dialogVisible){
            // this.$nextTick(() => {
            //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个
            // });
          }
        }
      });
    },
    queryEnergyStation() {
      this.loading = true;
      if(!this.requestParam.companyInfoId){
        console.log('~~~~~',this.requestParam.companyInfoId, this.checkedNodes);
        this.checkedNodes = this.treeData[0]
        this.requestParam.companyInfoId = this.treeData[0].id
      }
      if(this.requestParam.companyInfoId==1){
        this.requestParam.companyInfoId=''
      }
      energyStationApi.queryEnergyStation(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
  },
  watch: {
    status: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style scoped>
/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}
::v-deep .el-dialog__body {
  padding: 30px 20px !important;
}
.dialog-content {
  display: flex;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}
.content-left {
  width: 310px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
}
/* tree */
.tree {
  width: 310px;
  padding-left: 17px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin-top: 24px;
}
.tree :deep() .el-input {
  width: 202px;
  margin-top: 12px;
}
.tree :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}
.tree :deep() .el-input .el-input__inner {
  height: 35px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}
.tree :deep() .el-input__icon {
  color: #1f49b5;
}

.tree :deep() .el-tree {
  color: #aed6ff;
  margin-top: 12px;
}

.el-tree :deep() .el-tree-node {
  color: #aed6ff;
}
.el-tree :deep() .is-current {
  /* background: #03183f !important; */
  color: #ffc74a;
}
.el-tree :deep() .el-tree-node__content:hover {
  background: #071c3f;
  color: #ffc74a;
}
.el-tree
  :deep()
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #071c3f;
}

.el-tree :deep() .el-tree-node {
  background-color: #071c3f;
}
.el-tree :deep() .el-tree-node:focus > .el-tree-node__content {
  background-color: #071c3f;
}
/* 暂无数据 */
.el-tree :deep() .el-tree__empty-block {
  background: #031639;
}

.el-tree :deep() .el-tree__empty-text {
  color: #aed6ff;
  line-height: 150px;
}
.content-right {
  width: 837px;
  padding: 16px 0 16px 21px;
  border-radius: 3px;
  border: 1px solid #06224e;
  background-color: #071c3f;
  box-sizing: border-box;
  margin-left: 15px;
}
.query {
  display: flex;
  margin-bottom: 14px;
}
.query :deep() .el-input {
  width: 347px;
}
.query :deep() input {
  width: 347px;
  height: 35px;
  border-radius: 2px;
  border: 1px solid #09295b;
  background-color: #09244d;
  font-size: 13px;
  color: #aed6ff;
}

.query :deep() button {
  width: 68px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid rgba(121, 121, 121, 0);
  background-color: #176ad2;
  box-sizing: border-box;
  color: #fdfdfd;
  text-align: center;
  line-height: 36px;
  padding: 0;
}
/* 修改table表格样式 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: #09224b;
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 12px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 暂无数据 */
::v-deep .el-table__body-wrapper {
  background: #071734;
}

::v-deep .el-table__empty-text {
  color: aliceblue;
  line-height: 150px;
}
/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination :deep() .el-pagination {
  padding-left: 0;
}
.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}
.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}
.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination >>> .btn-prev,
.el-pagination >>> .btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}
.el-pagination >>> .el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}
.pagination :deep().el-select .el-input__inner {
  height: 30px;
  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}
.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}
::v-deep .el-tree-node__label{
  max-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
