<!-- 试验计划 -->
<template>
  <div id="inspection-plan">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>试验计划</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="计划名称">
              <el-input v-model="requestParam.planName" placeholder="请输入计划名称查询" clearable></el-input>
            </el-form-item>
            <el-form-item label="试验周期">
              <el-select v-model="requestParam.cycle" placeholder="请选择试验周期" :popper-append-to-body="false" clearable>
                <el-option label="日" :value="1"></el-option>
                <el-option label="周" :value="2"></el-option>
                <el-option label="月" :value="3"></el-option>
                <el-option label="季度" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运维执行人">
              <el-input v-model="requestParam.userName" placeholder="请输入运维执行人" clearable></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker type="date" placeholder="选择开始时间" value-format="yyyy-MM-dd"
                v-model="requestParam.startTime" :append-to-body="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker type="date" placeholder="选择结束时间" value-format="yyyy-MM-dd" v-model="requestParam.endTime"
                :append-to-body="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="执行状态">
              <el-select v-model="requestParam.state" placeholder="请选择执行状态" :popper-append-to-body="false" clearable>
                <el-option label="停止" :value="1"></el-option>
                <el-option label="启动" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button :class="isStringInArray('btnNewsyPlan') ? '' : 'btnShowAuthority'" type="primary" icon="el-icon-plus" @click="createDialog">新建计划</el-button>
          </div>
        </div>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="planName" label="计划名称" :fluid="true">
          </el-table-column>
          <el-table-column align="center" prop="planNumber" label="计划编号">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="运维单位">
          </el-table-column>
          <el-table-column align="center" prop="buyer" label="试验周期">
            <template slot-scope="scope">
              {{ scope.row.cycle == 1 ? '日' : scope.row.cycle == 2 ? '周' : scope.row.cycle == 3 ? '月' : '季度' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="principalName" label="运维负责人">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="运维执行人">
          </el-table-column>
          <el-table-column align="center" prop="userName" label="计划状态">
            <template slot-scope="scope">
              {{ scope.row.state == 1 ? '停止' : scope.row.state == 2 ? '启动' : scope.row.state == 'null' ? '未启动' : '未启动' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="开始时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="结束时间" width="175">
          </el-table-column>

          <el-table-column align="center" label="操作" prop="operate" width="250">
            <template slot-scope="scope">
              <div class="operate">
                <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                <span :class="isStringInArray('btnStart') ? '' : 'btnShowAuthority'">
                  <span v-if="scope.row.state != 2">|</span>
                  <el-button v-if="scope.row.state != 2" type="text" @click="alterDialog(scope.row, 2)">启动</el-button>
                </span>
                <span :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'">
                  <span v-if="scope.row.state != 2">|</span>
                  <el-button v-if="scope.row.state != 2" type="text" @click="updateDialog(scope.row)">编辑</el-button>
                </span>
                <span :class="isStringInArray('btnStop') ? '' : 'btnShowAuthority'">
                  <span v-if="scope.row.state != 1">|</span>
                  <el-button v-if="scope.row.state != 1" type="text" @click="alterDialog(scope.row, 1)">停止</el-button>
                </span>
                <span :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'">
                  <span>|</span>
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input size="mini" class="pagination-input" v-model.number="inputValue"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="requestType === 0 ? '新建试验计划' : '修改试验计划'" :visible.sync="dialogVisible" width="781px"
      @close="dialogClose('planForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="planForm" :inline="true" :model="planData" :rules="rules" label-width="80px">
          <el-form-item label="计划名称" prop="planName">
            <el-input v-model="planData.planName" placeholder="请输入计划名称"></el-input>
          </el-form-item>
          <div class="interval"></div>
          <div class="plan-button">
            <!-- <el-button type="primary" icon="el-icon-minus"></el-button>
              <el-button type="primary" icon="el-icon-plus"></el-button> -->
          </div>
          <div class="newItem">
            <el-button class="" type="primary" size="small" @click="addPlanList">添加</el-button>
          </div>
          <div class="plan">
            <div class="plan-content" v-for="(item, index) in planData.planList" :key="index">
              <div class="deleteIcon" @click="deletePlanList(index)" v-if="planData.planList.length > 1"><i class="el-icon-circle-close"></i></div>
              <el-form-item label="场站" :prop="'planList.' + index + '.stationId'"
                :rules="[{ required: true, message: '请输入场站', trigger: 'change' }]">
                <!-- <el-input v-model="planData.stationId"></el-input> -->
                <el-input class="operationStyle" placeholder="请选择变配电站" v-model="item.stationName" :readonly="true">
                  <template slot="append">
                    <span @click="selectStation(item, index)" class="appendSlot">选择配电站</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="运维单位" :prop="'planList.' + index + '.companyId'"
                :rules="[{ required: true, message: '请选择运维单位', trigger: 'change' }]">
                <!-- <el-input v-model="item.companyId" StaComList placeholder="请输入开始时间"></el-input> -->
                <el-select @change="(value) => selectCom(value, item, index)" v-model="item.companyId"
                  :popper-append-to-body="false" clearable placeholder="请选择运维单位">
                  <el-option v-for="Com in item.StaComList" :key="Com.companyId" :label="Com.companyName"
                    :value="Com.companyId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="单位负责人" :prop="'planList.' + index + '.principalName'"  >
              <el-input v-model="item.principalName" placeholder="请选择负责人" :readonly="true"></el-input>
            </el-form-item>
              <el-form-item label="运维负责人" :prop="'planList.' + index + '.principalId'" 
              :rules="[{ required: true, message: '请选择运维执行人', trigger: 'change' }]" >
              <!-- <el-input v-model="item.principalName" placeholder="请选择运维负责人" :readonly="true"></el-input> -->
              <el-select v-model="item.principalId" :popper-append-to-body="false" clearable placeholder="请选择执行人">
                  <el-option v-for="user in item.principalIdList" :key="user.userId" :label="user.userName"
                    :value="user.userId"></el-option>
                </el-select>
            </el-form-item>
              <el-form-item label="运维执行人" :prop="'planList.' + index + '.executeUserId'"
                :rules="[{ required: true, message: '请选择运维执行人', trigger: 'change' }]">
                <!-- <el-input v-model="item.executeUserId"></el-input> executeUserList -->
                <el-select v-model="item.executeUserId" :popper-append-to-body="false" clearable placeholder="请选择执行人">
                  <el-option v-for="user in item.executeUserList" :key="user.userId" :label="user.userName"
                    :value="user.userId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开始时间" :prop="'planList.' + index + '.startTime'"
                :rules="[{ required: true, message: '请选择开始时间', trigger: 'change' }]">
                <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
                <el-date-picker @change="() => { addItemTime(index) }" class="custom-picker" :append-to-body="false"
                  v-model="item.startTime" value-format="yyyy-MM-dd " type="date" placeholder="请选择开始时间"
                  :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间" :prop="'planList.' + index + '.endTime'"
                :rules="[{ required: true, message: '请选择结束时间', trigger: 'change' }]">
                <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
                <el-date-picker @change="() => { addItemTime(index) }" class="custom-picker" :append-to-body="false"
                  v-model="item.endTime" value-format="yyyy-MM-dd " type="date" placeholder="请选择结束时间"
                  :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
              <el-form-item label="计划周期" :prop="'planList.' + index + '.cycle'"
                :rules="[{ required: true, message: '请选择计划周期', trigger: 'change' }]">
                <el-select v-model="item.cycle" :popper-append-to-body="false" clearable placeholder="请选择计划周期">
                  <el-option label="日" :value="1"></el-option>
                  <el-option label="周" :value="2"></el-option>
                  <el-option label="月" :value="3"></el-option>
                  <el-option label="季度" :value="4"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="试验项">
                <div v-show="testItem.length === 0" class="no-data">暂无数据</div>
                <div class="" v-for="(itemLabel, labelIndex) in item.testItem " :key="labelIndex">
                  <div v-show="testItem.length !== 0"
                    :class="itemLabel.showStatus ? 'selected-inspection' : 'default-inspection'">
                    <div class="firstItem" @click="selectShow(itemLabel,index,labelIndex)">
                      <span>{{ itemLabel.experimentName }}</span>
                      <div>
                        <i v-if="itemLabel.showStatus" class="el-icon-arrow-up"></i>
                        <i v-else class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                    <div v-show="itemLabel.showStatus && itemLabel.operationExperimentItemPojos.length !== 0"
                      class="select-content" v-for="(element, key) in itemLabel.operationExperimentItemPojos" :key="key">
                      <span>{{ element.itemName }}</span>
                      <el-checkbox v-model="element.checked"></el-checkbox>
                    </div>
                    <div v-show="itemLabel.showStatus && itemLabel.operationExperimentItemPojos.length === 0" class="no-data">
                      暂无数据
                    </div>
                  </div>
                </div>
              </el-form-item>
              <!-- <div class="deleteItem" v-if="planData.planList.length > 1">
                <el-button class="" type="primary" size="small" @click="deletePlanList(index)">删除</el-button>
              </div> -->
            </div>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('planForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="requestType == 1 ? '修改试验计划' : '详情'" :visible.sync="updateDialogVisible" width="781px"
      @close="dialogClose('modifyPlanList')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="modifyPlanList" :inline="true" :model="modifyPlanList" :rules="rules"
          label-width="80px">
          <el-form-item label="计划名称" prop="planName">
            <el-input :disabled="requestType == 2" v-model="modifyPlanList.planName" placeholder="请输入计划名称"></el-input>
          </el-form-item>
          <div class="plan-content planUpdateDialog">
            <el-form-item label="场站" prop="operationFormName">
              <el-input :disabled="requestType == 2" class="operationStyle" placeholder="请选择变配电站"
                v-model="modifyPlanList.stationName" :readonly="true">
                <template slot="append">
                  <el-button :disabled="requestType !=1" @click="selectStationModify" class="appendSlot">选择配电站</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="运维单位" prop="companyId">
              <!-- <el-input v-model="item.companyId" StaComList placeholder="请输入开始时间"></el-input> -->
              <el-select :disabled="requestType == 2" @change="selectUser" v-model="modifyPlanList.companyId"
                :popper-append-to-body="false" clearable placeholder="请选择运维单位">
                <el-option v-for="Com in modifyPlanList.StaComList" :key="Com.companyId" :label="Com.companyName"
                  :value="Com.companyId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单位负责人"  >
              <el-input v-model="modifyPlanList.companyPrincipalName" placeholder="请输入计划名称" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="运维负责人" prop="principalId">
              <!-- <el-input v-model="item.executeUserId"></el-input> executeUserList -->
              <el-select :disabled="requestType ==2"  v-model="modifyPlanList.principalId" :popper-append-to-body="false" clearable placeholder="请选择执行人">
                <el-option v-for="user in modifyPlanList.principalIdList" :key="user.userId" :label="user.userName"
                  :value="user.userId"></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="执行人" prop="executeUserId">
              <!-- <el-input v-model="item.executeUserId"></el-input> executeUserList -->
              <el-select :disabled="requestType == 2" v-model="modifyPlanList.executeUserId"
                :popper-append-to-body="false" clearable placeholder="请选择运维执行人">
                <el-option v-for="user in modifyPlanList.executeUserList" :key="user.userId" :label="user.userName"
                  :value="user.userId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
              <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
              <el-date-picker :disabled="requestType == 2" @change="modifyItemTime" class="custom-picker"
                :append-to-body="false" v-model="modifyPlanList.startTime" value-format="yyyy-MM-dd " type="date"
                placeholder="请选择开始时间" :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
              <!-- <el-input v-model="item.startTime" placeholder="请输入开始时间"></el-input> -->
              <el-date-picker :disabled="requestType == 2" @change="modifyItemTime" class="custom-picker"
                :append-to-body="false" v-model="modifyPlanList.endTime" value-format="yyyy-MM-dd " type="date"
                placeholder="请选择结束时间" :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item label="计划周期" prop="cycle">
              <el-select :disabled="requestType == 2" v-model="modifyPlanList.cycle" :popper-append-to-body="false"
                clearable placeholder="请选择计划周期">
                <el-option label="日" :value="1"></el-option>
                <el-option label="周" :value="2"></el-option>
                <el-option label="月" :value="3"></el-option>
                <el-option label="季度" :value="4"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="试验项" prop="planName" >
              <div v-show="testItem.length === 0" class="no-data">暂无数据</div>
                <div class="" v-for="(item, index) in testItem " :key="index">
                  <div class="firstItemModify" @click="selectShowModify(item,index)">
                      <span>{{ item.experimentName }}</span>
                      <div>
                        <i v-if="item.showStatus" class="el-icon-arrow-up"></i>
                        <i  v-else class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                    <div v-show="item.showStatus && item.operationExperimentItemPojos.length !== 0" class="select-content" v-for="(element, key) in item.operationExperimentItemPojos" :key="key">
                <span>{{ element.itemName }}</span>
                <el-checkbox :disabled="requestType != 1" v-model="element.checked"></el-checkbox>
              </div>
              <div v-show="item.showStatus && item.operationExperimentItemPojos.length === 0" class="no-data">
                暂无数据
              </div>
                  </div>

            </el-form-item>
            
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" v-if="requestType != 2">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirmModify('modifyPlanList')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除计划" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该计划吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteOperationPlan">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="alterType == 2 ? '启动' : '停止'" :visible.sync="alterVisible" width="436px">
      <div class="dialog-text" v-if="alterType == 2">确定要启动该计划吗？</div>
      <div class="dialog-text" v-if="alterType == 1">确定要停止该计划吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="alterVisible = false">取 消</el-button>
        <el-button type="primary" @click="alterOperationPlan">确 定</el-button>
      </div>
    </el-dialog>
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationNameDialog"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>

<script>
import { operationPlanApi } from "@/api/operationPlan.js";
import stationDialog from "./compoents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      alterType: '',
      alterVisible: false,
      dialogStatus: false,
      stationId: 0,
      stationName: '',
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        state: "",
        userName: '',
        planName: '',
        planType: 2,
      },
      total: 0,
      inputValue: 1,
      requestType: 0,
      tableData: [],
      planData: {
        planName: '',//计划名称
        operationInspectionFormId: '',//试验单id
        planList: [
          {
            stationId: '',//场站
            stationName: '',
            startTime: '',//开始时间 
            endTime: '',//结束时间
            cycle: '',//计划周期
            executeUserId: '',//执行人
            executeUserList: [],//执行人列表
            principalIdList: [],//执行人列表
            companyId: '',//运维单位
            StaComList: [],//运维单位列表
            testItem:[], //试验项列表
            principalId:'',//负责人
            principalName:'',//负责人姓名
            createBy:'',//创建人

          },
        ],
      },

      modifyPlanList: {
        planName: '',//计划名称
        operationInspectionFormId: '',//试验单id
        stationId: '',//场站
        stationName: '',
        startTime: '',//开始时间 
        endTime: '',//结束时间
        cycle: '',//计划周期
        executeUserId: '',//执行人
        executeUserList: [],//执行人列表
        principalIdList: [],//执行人列表
        companyId: '',//运维单位
        StaComList: [],//运维单位列表
        principalId:'',//负责人
        principalName:'',//负责人姓名
        companyPrincipalName:'',
            // createBy:'',//创建人
      },
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      updateDialogVisible: false,
      id: "",

      rules: {
        planName: [
          { required: true, message: "请输入计划名称", trigger: "blur" },
        ],
        // operationInspectionFormId: [
        //   { required: true, message: "请选择试验单", trigger: "change" },
        // ],
        stationId: [
          { required: true, message: "请选择场站", trigger: "change" },
        ],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        cycle: [
          { required: true, message: "请选择计划周期", trigger: "change" },
        ],
        executeUserId: [
          { required: true, message: "请选择运维执行人", trigger: "change" },
        ],
        principalId: [
          { required: true, message: "请选择运维负责人", trigger: "change" },
        ],
        companyId: [
          { required: true, message: "请选择运维单位", trigger: "change" },
        ],
      },
      defaultData: {
        purchaseTime: "",
        buyer: "",
        remark: "",
        accomplishFlage: "",
      },
      testItem: [],
      planItem: '',
      index: '',
      authorityListBtn:[],
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate()); // 获取明天的日期

          return time.getTime() < tomorrow.getTime();
        }
      }
    };
  },
  created() {
    this.$store.commit("increment", "计划管理");
    this.$store.commit("selectChild", "试验计划");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryOperationPlan();
    this.inspectionList()
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
  },
  methods: {
      //按钮权限
      isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },

    //点击试验项
    selectShow(item,index,labelIndex) {
      console.log(item,index);
      console.log(this.planData.planList[index].testItem[labelIndex]);
      this.planData.planList[index].testItem[labelIndex].showStatus = !this.planData.planList[index].testItem[labelIndex].showStatus;
      // item.showStatus = !item.showStatus;
    },
    selectShowModify(item){
      item.showStatus = !item.showStatus;
    },


    //创建计划
    createOperationPlan() {
      // let obj = {
      //   stationId: '',//场站
      //   startTime: '',//开始时间 
      //   endTime: '',//结束时间 
      //   cycle: '',//计划周期
      //   executeUserId: '',//执行人
      //   companyId: '',//运维单位
      // }
      let submitForm = {
        planName: this.planData.planName,//计划名称
        // operationInspectionFormId: this.planData.operationInspectionFormId,//试验单id
        planType: 2,
        planList: [],
      }
      let type=true
      const value = JSON.parse(sessionStorage.getItem('user_info'));
      this.planData.planList.forEach(item => {
        let obj = {}
        
        obj.stationId = item.stationId
        obj.startTime = item.startTime
        obj.endTime = item.endTime
        obj.cycle = item.cycle
        obj.executeUserId = item.executeUserId
        obj.companyId = item.companyId
        obj.principalId = item.principalId
        obj.createBy=value.id
        // operationExperimentId:'' operationExperimentItemId
        console.log(item.testItem);
        let array = [];
      let typeId = [];
        item.testItem.forEach(itemSelect=>{
          if(itemSelect.operationExperimentItemPojos && itemSelect.operationExperimentItemPojos.length > 0){
            console.log("11234");
            let details=
            itemSelect.operationExperimentItemPojos.forEach(itemDetails=>{
              if(itemDetails.checked){
                // operationExperimentId.push(itemDetails.experimentId)
                // operationExperimentItemId.push(itemDetails.id)
                array.push(itemDetails);
                typeId.push(itemSelect.id);
              }
            })
          }
        })
        typeId = this.unique(typeId);
        let detailsId = [];
      for (let i = 0; i < typeId.length; i++) {
        let id = [];
        for (let j = 0; j < array.length; j++) {
          if (typeId[i] === array[j].experimentId) {
            id.push(array[j].id);
            // console.log("同一个分类下的详情id", id);
          }
          if (j === array.length - 1) {
            // console.log("循环结束");
            let string = id.toString();
            detailsId.push(string);
          }
        }
      }
      if (detailsId.length === 0) {
        type=false
        this.$message({
          message: "请选择试验项内容",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      obj.operationExperimentId = typeId.toString();
     obj.operationExperimentItemId = detailsId.join(";");
        // console.log(operationExperimentId,operationExperimentItemId);
      console.log(obj);
        submitForm.planList.push(obj)
      })
      console.log(submitForm);
      if(type){
        operationPlanApi.createOperationPlan(submitForm).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.dialogVisible = false;
            this.queryOperationPlan();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
      }
    },
    unique(array) {
      return array.reduce((prev, next) => {
        return prev.includes(next) ? prev : [...prev, next];
      }, []);
    },
    selectCom(value, item, index) {
      console.log(value, item, index);
      // obj= this.ComList.filter(item=>item.companyId===value)[0] StaComList executeUserList comUserList
      let obj = item.StaComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      this.planData.planList[index].executeUserList = obj.comUserList
      this.planData.planList[index].principalIdList = obj.fuzerenUserList
      // this.planData.planList[index].principalId= obj.contactNameId
      this.planData.planList[index].principalName= obj.contactName
      if (this.planData.planList[index].executeUserId) {
        this.planData.planList[index].executeUserId = ''
      }
      if(this.planData.planList[index].principalId){
        this.planData.planList[index].principalId=''
      }

    },
    selectUser(value) {
      let obj = this.modifyPlanList.StaComList.filter(item => item.companyId === value)[0]
      this.modifyPlanList.executeUserList = obj.comUserList
      this.modifyPlanList.principalIdList = obj.fuzerenUserList
      // this.modifyPlanList.principalId = obj.contactNameId
      this.modifyPlanList.companyPrincipalName = obj.contactName
      this.modifyPlanList.executeUserId = null
      this.modifyPlanList.principalId=null
    },
    //添加试验项
    addPlanList() {
      this.planData.planList.push(
        {
          stationId: '',//场站
          stationName: '',
          startTime: '',//开始时间 
          endTime: '',//结束时间 
          cycle: '',//计划周期
          executeUserId: '',//执行人
          executeUserList: [],//执行人列表
          companyId: '',//运维单位
          StaComList: [],//运维单位列表
          testItem:JSON.parse(JSON.stringify(this.testItem)), //试验项
          principalId:'',//负责人
          principalName:'',//负责人姓名
          createBy:'',//创建人
        }
      )
    },
    //删除试验项
    deletePlanList(index) {
      // console.log(this.planData.planList);
      this.planData.planList.splice(index,1)
    },
    dialogClose(planForm) {
      this.planData = this.$options.data().planData
      this.$refs[planForm].resetFields();
      this.index = ''
      this.inspectionList()
    },
    //新增
    dialogConfirm(planForm) {
      this.$refs[planForm].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createOperationPlan();
          } else {
            this.updatePurchase();
          }
        } else {
          return false;
        }
      });
    },
    //修改
    dialogConfirmModify(modifyPlanList) {
      this.$refs[modifyPlanList].validate((valid) => {
        if (valid) {
          this.updateOperationPlan();
        } else {
          return false;
        }
      });
    },
    //修改
    updateOperationPlan() {
      // this.planData.id = this.id;
      let obj = {
        planId: this.id,
        planName: this.modifyPlanList.planName,
        // operationInspectionFormId: this.modifyPlanList.operationInspectionFormId,
        stationId: Number(this.modifyPlanList.stationId) ,
        startTime: this.modifyPlanList.startTime,
        endTime: this.modifyPlanList.endTime,
        cycle: this.modifyPlanList.cycle,
        executeUserId: this.modifyPlanList.executeUserId,
        companyId: this.modifyPlanList.companyId,
        operationExperimentId:'',
        operationExperimentItemId:'',
        principalId:this.modifyPlanList.principalId,
      }
      let array = [];
      let typeId = [];
      this.testItem.forEach((item) => {
        item.operationExperimentItemPojos.forEach((element) => {
          if (element.checked) {
            array.push(element);
            typeId.push(item.id);
          }
        });
      });
      // console.log("选择的分类id", typeId);
      // console.log("选择的详情", array);
      typeId = this.unique(typeId);
      let detailsId = [];
      for (let i = 0; i < typeId.length; i++) {
        let id = [];
        for (let j = 0; j < array.length; j++) {
          if (typeId[i] === array[j].experimentId) {
            id.push(array[j].id);
            // console.log("同一个分类下的详情id", id);
          }
          if (j === array.length - 1) {
            // console.log("循环结束");
            let string = id.toString();
            detailsId.push(string);
          }
        }
      }
      if (detailsId.length === 0) {
        this.$message({
          message: "请选择试验项内容",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      obj.operationExperimentId = typeId.toString();
      obj.operationExperimentItemId = detailsId.join(";");


      operationPlanApi.updateOperationPlan(obj).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.updateDialogVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },


    //获取试验项
    inspectionList() {
      operationPlanApi.getExperimentList().then((res) => {
        if (res.code === 200) {
          res.data.forEach((element) => {
            element.showStatus = false;
            element.operationExperimentItemPojos.forEach((item) => {
              item.checked = false;
            });
          });
          this.testItem = res.data;
          this.planData.planList[0].testItem=JSON.parse(JSON.stringify(res.data))
          console.log(this.testItem);
        }
      })
    },

    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryEnergyStation();
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.planData, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      console.log(data);
      // this.planData.purchaseTime = data.purchaseTime;
      // this.planData.buyer = data.buyer;
      // this.planData.remark = data.remark;
      // this.planData.accomplishFlage = data.accomplishFlage;

      this.getDetails(data.id)

      this.updateDialogVisible = true;
    },
    //获取详情
    getDetails(id) {
      operationPlanApi.getById(id).then(res => {
        console.log(res);
                //试验项反显
        // testItem
        let inputString = res.data.operationExperimentItemId;
        let result = [];
        if (inputString.includes(';')) {
        let groups = inputString.split(';');
        groups.forEach(group => {
          let numbers = group.split(',');
          result = result.concat(numbers.map(Number));
        });
      } else {
        result = inputString.split(',').map(Number);
      } 
      console.log(result);

      this.testItem.forEach(item=>{
        if(item.operationExperimentItemPojos.length > 0){
          item.operationExperimentItemPojos.forEach(ele=>{
            if(result.includes(ele.id)){
              console.log("222222222");
              ele.checked=true
              item.showStatus=true
              return ele
            }else{
              ele.checked=false
            }
          })
        }
      })
        this.stationId = Number(res.data.stationId)
        this.modifyPlanList.planName = res.data.planName
        this.modifyPlanList.companyPrincipalName=res.data.companyPrincipalName
        this.modifyPlanList.operationInspectionFormId = Number(res.data.operationInspectionFormId)
        this.modifyPlanList.stationId = res.data.stationId
        this.modifyPlanList.stationName = res.data.stationName
        this.modifyPlanList.startTime = res.data.startTime
        this.modifyPlanList.endTime = res.data.endTime
        this.modifyPlanList.cycle = res.data.cycle
        this.modifyPlanList.executeUserId = Number(res.data.executeUserId)
        this.modifyPlanList.companyId = res.data.companyId
        this.modifyPlanList.principalId=res.data.principalId
        this.modifyPlanList.principalName=res.data.principalName
        console.log(+this.stationId);
        operationPlanApi.getStaComList(Number(this.stationId)).then(response => {
          this.modifyPlanList.StaComList = response.data
          if (res.data.executeUserId && res.data.companyId) {
            let obj = this.modifyPlanList.StaComList.filter(item => item.companyId === res.data.companyId)[0]
            this.modifyPlanList.executeUserList = obj.comUserList
            this.modifyPlanList.principalIdList = obj.fuzerenUserList
          }
        })


      })

    },
    //详情
    viewDialog(data) {
      this.id = data.id;
      // this.viewFile();
      this.getDetails(data.id)
      this.requestType = 2
      this.updateDialogVisible = true;
    },
    deleteDialog(data) {
      this.id = data.id;
      this.deleteType = 0;
      if(data.state==2){
        this.$message({
            message: '请先停止该任务后再删除',
            type: 'warning',
            duration: 3000,
            customClass: "messageText",
          });
      }else{
        this.deleteVisible = true;
      }
    },
    //场站选择选中时返回的
    getStationNameDialog(value) {
      console.log("222222", this.index);
      if (this.index !== '') {
        this.planData.planList[this.index].stationId = value.id
        this.planData.planList[this.index].stationName = value.stationName
        operationPlanApi.getStaComList(value.id).then(res => {
          this.planData.planList[this.index].StaComList = res.data
          if (this.planData.planList[this.index].companyId) {
            this.planData.planList[this.index].companyId = null
          }
          if (this.planData.planList[this.index].executeUserId) {
            this.planData.planList[this.index].executeUserId = null
          }
          // this.planData.planList[this.index].executeUserId = null
        })
      } else {
        this.modifyPlanList.stationId = value.id
        this.modifyPlanList.stationName = value.stationName
        operationPlanApi.getStaComList(value.id).then(res => {
          this.modifyPlanList.StaComList = res.data
          this.modifyPlanList.companyId = null
          this.modifyPlanList.executeUserId = null
        })
      }
      this.dialogStatus = false;

    },
    //场站选择关闭时返回的
    changeDialog(value) {
      // this.stationId = 0
      this.dialogStatus = false;
    },
    selectStation(el, index) {
      console.log(el, index);
      this.planItem = el
      this.index = index
      this.stationId = Number( el.stationId ? el.stationId : 0)
      this.dialogStatus = true;
    },
    selectStationModify() {
      // console.log(el, index);
      // this.planItem = el
      // this.index = index
      this.stationId = this.modifyPlanList.stationId ? this.modifyPlanList.stationId : 0
      this.stationId=Number(this.stationId)
      this.dialogStatus = true;
    },
    //新增时时间校验
    addItemTime(index) {
      let startTime = new Date(this.planData.planList[index].startTime).getTime();
      let endTime = new Date(this.planData.planList[index].endTime).getTime();
      if (startTime > endTime) {
        this.$message({
          message: '开始时间需要小于结束时间并且不能相等',
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });
        this.planData.planList[index].endTime = null
      }
    },
    //新增时时间校验
    modifyItemTime() {
      let startTime = new Date(this.modifyPlanList.startTime).getTime();
      let endTime = new Date(this.modifyPlanList.endTime).getTime();
      if (startTime > endTime) {
        this.$message({
          message: '开始时间需要小于结束时间并且不能相等',
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });
        this.modifyPlanList.endTime = null
      }
    },

    //分页相关
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryOperationPlan();
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryOperationPlan();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryOperationPlan();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryOperationPlan();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryOperationPlan();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperationPlan();
    },
    //查询
    queryOperationPlan() {
      operationPlanApi.queryOperationPlan(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    deleteOperationPlan() {
      operationPlanApi.deleteOperationPlan({ planId: this.id }).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    //启动/停止
    alterOperationPlan() {
      let obj = {
        planId: this.id,
        state: this.alterType
      }
      operationPlanApi.alterOperationPlan(obj).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.alterVisible = false;
          this.queryOperationPlan();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }

      })

    },
    //按钮启动或者停止
    alterDialog(el, type) {
      this.alterType = type
      this.id = el.id
      this.alterVisible = true
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#inspection-plan {
  width: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

/* dialog form */
.dialog-content {
  margin: 18px 50px 0 47px;
}

.dialog-form :deep().el-form-item__label {
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  padding: 0;
}

.dialog-form :deep() .el-input .el-input__inner {
  width: 565px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
  height: 40px !important;
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.plan {
  /* display: flex; */
  padding-top: 20px;
  max-height: 700px;
  overflow: auto;
}

::v-deep .plan::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .plan::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

::v-deep .plan::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

.plan-content {
  /* width: 574px; */
  padding: 16px 16px 10px 16px;
  margin-bottom: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  border: 1px solid #06224e;
  position: relative;
}

.plan-content :deep() .el-input .el-input__inner {
  width: 520px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.plan-content :deep() .operationStyle .el-input__inner {
  width: 410px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.planUpdateDialog {
  border: 0px;
  padding: 0;
}

.planUpdateDialog :deep() .el-input .el-input__inner {
  width: 560px;
  /* width: 100%; */
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.planUpdateDialog :deep() .operationStyle .el-input__inner {
  width: 455px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

::v-deep .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.plan-button button {
  height: 30px;
  width: 30px;
  padding: 0;
  background-color: #176ad2;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #e8f4ff;
  border: 0;
  margin-left: 10px;
}

.newItem {
  margin: 0 0 10px 10px;
  display: flex;
  flex-direction: row-reverse;
}
.deleteIcon{
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.deleteItem {
  margin: 0 0 10px 10px;
  display: flex;
  flex-direction: row-reverse;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.no-data {
  height: 60px;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  background-color: #041c4c;
  margin:0 0  16px 0;
}

.selected-inspection {
  background-color: #041c4c;
}

.select-content {
  padding: 0 16px;
  background-color: #041c4c;
  border-bottom: 1px solid #06224e;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.select-content span {
  color: #aed6ff;
  font-size: 12px;
}

.firstItem {
  color: #c9d4f1;
  border: 1px solid #06224e;
  width: 520px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
.firstItemModify {
  color: #c9d4f1;
  border: 1px solid #06224e;
  width: 560px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-button.is-disabled:hover{
  background: #143168;
  border-color: #DCDFE6;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
  background-color: #409EFF;
    border-color: #409EFF;
}
.btnShowAuthority{
  display: none;
}
</style>
