import { getRequest, postRequest, gateway, urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 运维计划管理
export const operationPlanApi = {
  // 运维计划列表
  queryOperationPlan: (params) => {
    return getRequest(`${gateway}/operation/plan/getOperationPlanList`, params);
  },
  // 创建运维计划
  createOperationPlan: (params) => {
    return postRequest(`${gateway}/operation/plan/addOperationPlan`, params);
  },
  // 修改运维计划
  updateOperationPlan: (params) => {
    return postRequest(`${gateway}/operation/plan/updOperationPlan`, params);
  },
  // 删除运维计划
  deleteOperationPlan: (params) => {
    return getRequest(`${gateway}/operation/plan/delOperationPLanById`, params);
  },
  //获取巡检单
  inspectionList: () => {
    return getRequest(`${urlTitle}/operation/inspection/form/list`);
  },
  //获取运维单位
  getStaComList: (params) => {
    return getRequest(`${urlTitle}/station/info/getStaComList?stationId=${params}`);
  },
  //启动/停止 计划
  alterOperationPlan: (params) => {
    return getRequest(`${gateway}/operation/plan/startOrStopOperationPlan`,params);
  },
  //根据id获取详情
  getById:(params) => {
    return getRequest(`${gateway}/operation/plan/getById?planId=${params}`);
  },
  //获取所有试验项
  getExperimentList: () => {
    return getRequest(`${gateway}/operation/experiment/task/all`)
  }

};
